import React, { FC, ReactNode } from "react";

import { HighlightGreen, HighlightOrange, Wrapper } from "./styles";

interface Props {
  children: ReactNode;
  height?: string;
  align?: string;
  hasEffect?: boolean;
  color?: string;
}

const CustomBackground: FC<Props> = ({ children, height, align, color, hasEffect }) => {
  return (
    <Wrapper height={height} color={color} align={align} hasEffect={hasEffect}>
      {children}
      {hasEffect && (
        <>
          <HighlightOrange />
          <HighlightGreen />
        </>
      )}
    </Wrapper>
  );
};

export default CustomBackground;
