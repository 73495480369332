import styled, { css } from "styled-components";
import { COLORS } from "../../constants/colors";

interface Props {
  height?: string;
  align?: string;
  color?: string;
  hasEffect?: boolean;
}

const commonHighLightBg = css`
  width: 50vw;
  height: 50vh;
  display: block;
  position: absolute;
  filter: blur(150px);
`;

export const Wrapper = styled.div<Props>`
  background-color: ${({ hasEffect, color }) => hasEffect ? COLORS.GRAY.MAIN : color ? color : COLORS.ORANGE};
  width: 100%;
  height: ${({ height }) => (height ? height : "100vh")};
  display: flex;
  align-items: ${({ align }) => (align ? align : "center")};
  justify-content: center;
  position: relative;
  overflow: hidden;

  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
`;

export const HighlightOrange = styled.div`
  ${commonHighLightBg}
  top: 0;
  right: 0;
  background-color: ${COLORS.ORANGE};
`;

export const HighlightGreen = styled.div`
  ${commonHighLightBg}
  bottom: 0;
  left: 0;
  background-color: ${COLORS.GREEN["-1"]};
`;