import styled from "styled-components";
import Heading from "../../components/heading";
import Text from "../../components/text";

import { COLORS } from "../../constants/colors";

export const Wrapper = styled.section``;

export const TitleContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 44px 24px;
  display: flex;
  justify-content: flex-start;
  transition: ease 0.2s;
  z-index: 1;

  ${({ theme }) => theme.media.x`
    max-width: 1024px;
  `}

  ${({ theme }) => theme.media.md`
    justify-content: center;
  `}
`;

export const Title = styled(Heading)`
  color: ${COLORS.WHITE};
`;

export const ContentWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: 100%;
  max-width: 1245px;
  padding: 40px 24px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.media.x`
    max-width: 1060px;
  `}
`;

export const HeadingStyled = styled(Heading)`
  font-family: "Inter";
`;

export const SubHeadingStyled = styled(Heading)`
  font-family: "Inter";
`;

export const TextStyled = styled(Text)``;

export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ListStyled = styled.li`
  color: ${COLORS.GRAY.MAIN};
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;

  list-style: inside;
  padding-left: 10px;

  ${({ theme }) => theme.media.x`
    font-size: 0.925em;
  `}

  ${({ theme }) => theme.media.md`
    font-size: 1em;
  `}
`;

export const SubListStyled = styled.li`
  color: ${COLORS.GRAY.MAIN};
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;

  list-style: inside;
  padding-left: 30px;

  ${({ theme }) => theme.media.x`
    font-size: 0.925em;
  `}

  ${({ theme }) => theme.media.md`
    font-size: 1em;
  `}
`;
