import React from "react";
import CustomBackground from "../../components/customBackground";

import {
  TitleContent,
  Title,
  Wrapper,
  ContentWrapper,
  TextStyled,
  HeadingStyled,
  SubHeadingStyled,
  ListStyled,
  SubListStyled,
  ListWrapper,
} from "./styles";

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <CustomBackground height="226px" align="flex-end">
        <TitleContent>
          <Title component="h2">Política de privacidade</Title>
        </TitleContent>
      </CustomBackground>
      <ContentWrapper>
        <TextStyled component="span">
          A sua privacidade é importante para nós. É nossa política respeitar a
          sua privacidade em relação a qualquer informação sua que possamos
          coletar.
        </TextStyled>

        <HeadingStyled component="h4">
          1. Para possibilitar o acesso e correta execução das funcionalidades e
          serviços oferecidos, poderão ser coletados dados pessoais dos
          Usuários. A seguir, o Usuário saberá um pouco mais sobre a nossa
          Política de tratamento de dados.
        </HeadingStyled>
        <TextStyled component="p">
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para lhe fornecer um serviço. Fazemos a coleta e a utilização
          dos dados sempre por meios justos e legais. Também informamos por que
          estamos coletando e como serão usados os dados.
        </TextStyled>
        <TextStyled component="p">
          Além desses dados acima, poderão ser coletadas outras informações
          automaticamente quando o Usuário acessar o site, tais como
          características do dispositivo e navegador, Protocolo de Internet (IP,
          com data e hora e origem) e outros. O site também poderá utilizar
          outras tecnologias, tais como cookies, pixel tags, beacons e local
          shared objects.
        </TextStyled>
        <HeadingStyled component="h4">
          2. Quais dados Pessoais serão coletados?
        </HeadingStyled>
        <HeadingStyled component="h4">3. Política de Cookies</HeadingStyled>
        <SubHeadingStyled component="h5">
          3.1. O que são cookies?
        </SubHeadingStyled>
        <TextStyled component="p">
          Como é prática comum em quase todos os sites profissionais, este site
          usa cookies, que são pequenos arquivos baixados no seu computador,
          para melhorar sua experiência. Esta página descreve quais informações
          eles coletam, como as usamos e por que às vezes precisamos armazenar
          esses cookies. Também compartilharemos como você pode impedir que
          esses cookies sejam armazenados, no entanto, isso pode fazer o
          “downgrade” ou “quebrar” certos elementos da funcionalidade do site.
        </TextStyled>
        <SubHeadingStyled component="h5">
          3.2. Como usamos os cookies?
        </SubHeadingStyled>
        <TextStyled component="p">
          Utilizamos cookies por vários motivos, detalhados abaixo.
          Infelizmente, na maioria dos casos, não existem opções padrão do setor
          para desativar os cookies sem desativar completamente a funcionalidade
          e os recursos que eles adicionam a este site. É recomendável que você
          deixe todos os cookies se não tiver certeza se precisa ou não deles,
          caso sejam usados ​​para fornecer um serviço que você usa.
        </TextStyled>
        <SubHeadingStyled component="h5">
          3.3. Desativar cookies
        </SubHeadingStyled>
        <TextStyled component="p">
          Você pode impedir a configuração de cookies ajustando as configurações
          do seu navegador (consulte a Ajuda do navegador para saber como fazer
          isso). Esteja ciente de que a desativação de cookies afetará a
          funcionalidade deste e de muitos outros sites que você visita. A
          desativação de cookies geralmente resultará na desativação de
          determinadas funcionalidades e recursos deste site.
        </TextStyled>
        <SubHeadingStyled component="h5">
          3.4. Cookies que usamos:
        </SubHeadingStyled>
        <ListWrapper>
          <ListStyled>Cookies relacionados à conta</ListStyled>
          <SubListStyled>
            Se você criar uma conta conosco, usaremos cookies para o
            gerenciamento do processo de inscrição e administração geral. Esses
            cookies geralmente serão excluídos quando você sair do sistema,
            porém, em alguns casos, eles poderão permanecer posteriormente para
            lembrar as preferências do seu site ao sair.
          </SubListStyled>
          <ListStyled>Cookies relacionados ao login</ListStyled>
          <SubListStyled>
            Utilizamos cookies quando você está logado, para que possamos
            lembrar dessa ação. Isso evita que você precise fazer login sempre
            que visitar uma nova página. Esses cookies são normalmente removidos
            ou limpos quando você efetua logout para garantir que você possa
            acessar apenas a recursos e áreas restritas ao efetuar login.
          </SubListStyled>
          <ListStyled>Cookies relacionados a boletins por e-mail</ListStyled>
          <SubListStyled>
            Este site pode oferecer serviços de assinatura de boletim
            informativo ou e-mail e os cookies podem ser usados ​​para lembrar
            se você já está registrado e se deve mostrar determinadas
            notificações válidas apenas para usuários inscritos / não inscritos.
          </SubListStyled>
          <ListStyled>Cookies relacionados a pesquisas</ListStyled>
          <SubListStyled>
            Periodicamente, podemos oferecer pesquisas e questionários para
            fornecer informações interessantes, ferramentas úteis ou para
            entender nossa base de usuários com mais precisão. Essas pesquisas
            podem usar cookies para lembrar quem já participou numa pesquisa ou
            para fornecer resultados precisos após a alteração das páginas.
          </SubListStyled>
          <ListStyled>Cookies relacionados a formulários</ListStyled>
          <SubListStyled>
            Quando você envia dados por meio de um formulário como os
            encontrados nas páginas de contato ou nos formulários de
            comentários, os cookies podem ser configurados para lembrar os
            detalhes do usuário para correspondência futura.
          </SubListStyled>
        </ListWrapper>
        <TextStyled component="span">
          <strong>
            Com a aceitação da Política pelo Usuário, este concorda e permite o
            acesso aos seus dados a partir do seu primeiro acesso ou utilização
            do Site.
          </strong>
        </TextStyled>
        <HeadingStyled component="h4">
          4. Como os dados serão armazenados e protegidos?
        </HeadingStyled>

        <TextStyled component="p">
          Para proteger suas informações pessoais, tomamos precauções razoáveis
          e seguimos as melhores práticas para nos certificar que elas não serão
          perdidas inadequadamente, usurpadas, acessadas, divulgadas, alteradas
          ou destruídas.
        </TextStyled>
        <TextStyled component="p">
          O Site utiliza o certificado “secure socket layer” (SSL), um mecanismo
          usado para codificar informações por meio da criptografia. O objetivo
          do Certificado SSL é proteger dados pessoais ou sigilosos que circulam
          na web. Os dados serão armazenados pelo menor tempo necessário,
          observando requisitos legais e regulatórios.
        </TextStyled>
        <TextStyled component="p">
          A exclusão dos dados se dará de forma definitiva no prazo julgado
          conveniente pelo Site, observados os requisitos legais e regulatórios,
          ou mediante solicitação expressa do Usuário.
        </TextStyled>
        <TextStyled component="span">
          <strong>
            A exclusão de dados poderá inviabilizar o acesso do Usuário a
            algumas funcionalidades do Site.
          </strong>
        </TextStyled>

        <HeadingStyled component="h4">
          5. Como o Usuário deve fazer para pedir a exclusão de dados?
        </HeadingStyled>

        <TextStyled component="p">
          Caso queria pedir a exclusão dos seus Dados Pessoais, o Usuário deverá
          enviar e-mail para o seguinte endereço: contato@fitce.com.br
          informando quais dados deseja que sejam excluídos.
        </TextStyled>
        <TextStyled component="p">
          O encarregado da proteção de dados do Site responderá ao e-mail do
          Usuário informando se a exclusão solicitada acarretará a
          impossibilidade de acesso a alguma funcionalidade e pedirá a
          confirmação do Usuário quanto ao pedido de exclusão da dados.
        </TextStyled>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivacyPolicy;
