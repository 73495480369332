import React from "react";
import Layout from "../components/layout";

import PrivacyPolicy from "../views/privacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <PrivacyPolicy />
    </Layout>
  );
};

export default PrivacyPolicyPage;
